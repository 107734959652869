<template>
  <div>
    <click-to-edit
      v-model="scale.fact"
      type="select"
      :select-options="scale.scopes"
      select-option-id="id"
      select-option-name="target"
      select-cast-value-to-int
      :disabled="disabled"
      placeholder=""
      @input="$emit('change', scale.fact)"
    ></click-to-edit>
  </div>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import ClickToEdit from "@/components/ClickToEdit";

export default {
  name: "design-card-fact-view",
  mixins: [dynamicScaleComponent],
  components: {ClickToEdit},

  props: {
    disabled:{type: Boolean, default: false}
  },

  data() {
    return {
      scale:{
        scopes: null,
        fact: null,
      },
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {

  }
}
</script>

<style>

</style>